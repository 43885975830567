import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[appCustomIBANValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: CustomIBANValidatorDirective, multi: true }],
})
export class CustomIBANValidatorDirective implements Validator {
  @Input('backAccInput') backAcc = '';

  validate(control: AbstractControl): ValidationErrors | null {
    return this.backAcc ? customIBANValidator(new RegExp(this.backAcc, 'i'), new RegExp(this.backAcc, ''))(control) : null;
  }
}

export function customIBANValidator(commonIBANReg: RegExp, nlIBANReg: RegExp): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null || (!control.value && control.value == '')) {
      return null;
    }

    if (control.value.length > 1 && control.value.slice(0, 2) == 'NL') {
      const validateRes = nlIBANReg.test(control.value);
      return !validateRes ? { backAcc: { value: control.value } } : null;
    }

    const validateRes = commonIBANReg.test(control.value);
    return !validateRes ? { backAcc: { value: control.value } } : null;
  };
}
